import "./header.css";

import { Link } from "gatsby";
import React from "react";

const Header = () => {
  return (
    <header className="Header">
      <Link to="/" className="Header__link">
        <img src={"/images/serply_logo.svg"} />
      </Link>

      <Link
        target="_blank"
        to="https://app.serply.io"
        className="Header__link"
        activeClassName="Header__link--active"
      >
        Signup
      </Link>
    </header>
  );
};

export default Header;
